<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              404 - sidan hittades inte
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/" class="breadcrumbs-link"> Hem</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <div class="col-lg-12">Den här sidan finns inte.</div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';

export default {
  components: {
    LayoutMain,
  },
};
</script>
